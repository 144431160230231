.imageStyle {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: "40%";
    padding:"2%";
  }

.dragStyle{
    flex: 1;
    display: 'flex';
    flex-direction: 'column';
    width:"50%";
    align-items: 'center';
    padding: '2%';
    border-width: 2;
    border-radius: 8;
    border-color: '#eeeeee';
    border-style: 'dashed';
    background-color: '#fafafa';
    color: '#bdbdbd';
    outline: 'none';
    transition: 'border .24s ease-in-out';
}

.drp-img-text{
  font-size: 2.4rem;
  color: #23d3c9;
  /*text-transform: uppercase;*/
  text-align: center;
}


.drp-img-text2 {
    font-size: 1.6rem;
    color: #179bcb;
    /*text-transform: uppercase;*/
    text-align: center;
  }