.SectionComponent {
  // Ensure container is above bgImage
  .container {
    position: relative;
  }

  // Add light border if two white
  // sections next to each other.
  .bg-white + &.bg-white {
    border-top: 1px solid #F0F0F0;
  }
}

/*.bg-toolbar {
    //background-color: lightgray;
}*/

.bg-image-area {
    //background-color: greenyellow;
    //flex-grow: 1;
    padding-top: 0.5rem !important;
    padding-bottom: 0.3px !important;
}


.bg-image-area > div {
    padding: 0px !important;
}