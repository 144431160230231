.toolbar-con {
  align-items: center;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 2px;  

}

.toolbar-con2 {
    @extend .toolbar-con;
}

@media only screen and (min-width: 769px) {
    .toolbar-con2 {
        align-items: center;
        border-radius: 5px;
        padding-left: 2px;
        padding-right: 2px;  
      
        //background-color: #d3d3d36e;    
        border-radius: 25px;
        border-style: solid;
        border-width: 1px;
        border-color: #ffffff57;    
      }
}

.toolbar-con3 {
    background-color: #d3d3d34d;
    padding-top: 3px !important;
    border-radius: 25px;
    //border: solid 1px grey;
}

/*@media only screen and (min-width: 600px) {
    .toolbar-col {
        background-color: #d3d3d38f;
        //border: solid 1px grey;
        border-radius: 5px;
    }
}*/

.toolbar-col-item{
  padding-left: 2px;
  padding-right: 2px;
}

.txtToolbarLabel{
  color: white;
}

.imgRangeSlider {
    flex-grow: 4;
    margin-left: 3px;
    margin-right: 3px;
}
.imgSliderSmall {
    flex-grow: 1;
    max-width: 15px;
    max-height: 20px;
    opacity: 0.4;
}

.imgSliderBig {
    flex-grow: 1;
    max-width: 25px;
    max-height: 25px;
    opacity: 0.3;
}